<template>
  <div class="not-found">
    <div class="menu">
      <IconButton class="menu__button button--lg button--icon-white" @click="searchActive = true">
        <IconSearch></IconSearch>
      </IconButton>
      <IconButton class="menu__button button--lg button--icon-white" @click="showMenu">
        <IconMenu></IconMenu>
      </IconButton>
    </div>
    <div class="not-found__container">
      <h1 class="not-found__title">Ooops!</h1>
      <BaseButton
        class="not-found__button button--white button--not-found"
        text="BACK TO HOME"
        @click.native="goToPage('Home')"
      />
    </div>
    <MenuNavigation
      :menuActive="menuActive"
      @hidenMenu="hidenMenu"
      @showNewsletter="subscriptionActive = true"
    ></MenuNavigation>
    <EmailSubscription
      :subscriptionActive="subscriptionActive"
      @hidenSubscription="subscriptionActive = false"
    ></EmailSubscription>
    <Search :searchActive="searchActive" @hidenSearch="hidenSearch" @showMenu="showMenu"></Search>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import MenuNavigation from "@/components/menu/MenuNavigation.vue";
import EmailSubscription from "@/components/menu/EmailSubscription.vue";
import Search from "@/components/menu/Search.vue";

export default {
  components: {
    BaseButton,
    IconButton,
    IconSearch,
    IconMenu,
    MenuNavigation,
    EmailSubscription,
    Search,
  },

  data() {
    return {
      menuActive: false,
      searchActive: false,
      subscriptionActive: false,
    };
  },

  computed: {},

  methods: {
    goToPage(page) {
      this.$router.push({ name: page });
    },
    showMenu() {
      this.searchActive = false;
      this.menuActive = true;
    },
    hidenMenu() {
      this.menuActive = false;
    },
    hidenSearch() {
      this.searchActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  background: url("../assets/images/404.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media only screen and (max-width: $xs) {
    background: url("../assets/images/404-mobile.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &__container {
    width: 38%;
    padding: 6rem 0rem 0rem 8rem;
    display: flex;
    flex-direction: column;
    /* @media only screen and (max-width: $lg) {
      width: 75%;
      padding: 15rem 0rem 0rem 12.4rem;
    } */
    @media only screen and (max-width: $xs) {
      align-items: center;
      width: 100%;
      padding: 12.2rem 6.8rem 0rem;
    }
  }
  &__title {
    font-family: $fontFrutigerNeue;
    font-weight: 500;
    font-size: 5rem;
    line-height: 1.2;
    letter-spacing: -1.33px;
    color: $white;
    @media only screen and (max-width: $xs) {
      font-size: 7.7rem;
      letter-spacing: -2px;
    }
  }
  &__button {
    margin-top: 4rem;
    @media only screen and (max-width: $xs) {
    }
  }
}

.menu {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  padding: 4rem 10.5rem 2rem;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: $md) {
    padding: 4rem 5rem 2rem;
  }
  @media (max-width: $sm) {
    padding: 40px 25px 15px 25px;
  }
  &__button {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    height: 6.4rem;
    width: 6.4rem;
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
  }
  .button:first-of-type {
    margin-right: 1rem;
  }
}
</style>
